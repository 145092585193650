<template>
  <div class="up_box" :class="!system.isPhone ? 'pc' : 'wap'">
    <el-form-item :prop="prop" :label="label">
      <div class="flex_box">
        <el-upload
          action="#"
          list-type="picture-card"
          :before-upload="beforeUpload"
          :file-list="value"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: ["value", "label", "prop"],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      uploading: false
      // value: []
    };
  },
  methods: {
    handleRemove(file) {
      this.value.forEach((item, index) => {
        if (item.url == file.url) {
          this.value.splice(index, 1);
        }
      });
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或Png 格式!");
        return false;
      }

      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }

      this.uploading = true;
      const formData = new FormData();
      // const data = {
      //   kind: "websiteQualification"
      // };
      formData.append("file", file);
      formData.append("kind", "websiteQualification");
      formData.append("sourceId", 1);
      this.$request("/api/front/daemon/files/upload", {
        method: "POST",
        data: formData,
        requestType: "form"
      })
        .then(res => {
          this.uploading = false;
          if (res.code === 1) {
            this.value.push({
              name: res.data.name,
              url: res.data.linkUrl
            });
            console.log(this.value, "内部this.value");
          }
        })
        .catch(err => {
          console.log(err);
          this.uploading = false;
        });
      return false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
</script>
