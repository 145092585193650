<template>
  <div class="up_box" :class="!system.isPhone ? 'pc' : 'wap'">
    <el-form-item :prop="prop" :label="label" :rules="rules">
      <div class="flex_box">
        <el-upload
          class="uplode"
          :class="!system.isPhone ? 'pc_xianzhi' : ''"
          action="#"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeUpload"
          :http-request="() => {}"
          :disabled="disabled"
        >
          <img
            :src="gerUrl"
            class="uplode_icon"
            :class="!system.isPhone ? 'pc_xianzhi' : ''"
          />
        </el-upload>
        <div class="tip">
          <slot name="tip">
            请上传一张{{ tip }}照片
            <br />
            大小{{ limit }}M以内，支持jpg，png格式的照片
            <br />
            要求内容清晰可见。
          </slot>
        </div>
      </div>
      <div class="bottom_tip_box">
        <slot name="bottomTip"></slot>
      </div>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  props: {
    value: [Array, Object, String],
    prop: [Array, Object, String],
    keyName: [String],
    label: [String],
    tip: [String],
    asyncUpdate: {
      type: Boolean,
      default: () => false,
    },
    limit: {
      type: Number,
      default: 5,
    },
    isSize: {
      type: Object,
      default: null,
      // default: () => {
      //   return { width: 500, height: 500, scale: true };
      // }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 校验方法
    rules: {
      type: [Array, Object, Function],
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      imageUrl: require("@/assets/img/apply/" + this.keyName + ".png"),
      // info: {},
      // dialogImageUrl: "",
      // dialogVisible: false,
      // disabled: false,

      // valueData: this.value
    };
  },

  computed: {
    gerUrl() {
      let url = require("@/assets/img/apply/" + this.keyName + ".png");
      if (this.asyncUpdate == true) {
        return this.value ? this.value : url;
      } else {
        return this.value ? this.value : this.imageUrl;
      }
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    getImgSize(file) {
      return new Promise((resolve) => {
        let { width, height, scale = null } = this.isSize;
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.src = _URL.createObjectURL(file);
        image.onload = () => {
          let valid = image.width == width && image.height == height;
          if (scale) {
            valid = width / height == image.width / image.height;
          }
          if (!valid) {
            this.$message.error("上传图片宽高信息不符合要求，请按照要求上传!");
          }
          image = null;
          _URL = null;
          resolve(valid);
        };
      });
    },
    async beforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < this.limit;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或Png 格式!");
        return false;
      }

      if (!isLt5M) {
        this.$message.error(`图片大小不能超过${this.limit}MB!`);
        return false;
      }

      if (this.isSize && this.isSize.width > 0 && this.isSize.height > 0) {
        let result = await this.getImgSize(file);
        if (!result) {
          return;
        }
      }
      console.log("异步完");
      this.uploading = true;
      const formData = new FormData();
      // const data = {
      //   kind: "websiteQualification"
      // };
      formData.append("file", file);
      formData.append("kind", "websiteQualification");
      formData.append("sourceId", 1);
      this.$request("/api/front/daemon/files/upload", {
        method: "POST",
        data: formData,
        requestType: "form",
      })
        .then((res) => {
          console.log(res);
          this.uploading = false;
          if (res.code === 1) {
            this.imageUrl = res.data.linkUrl;
            this.change(res.data.linkUrl);
          }
        })
        .catch((err) => {
          console.log(err);
          this.uploading = false;
        });
      return false;
    },
    change(value) {
      console.log(value);
      this.$emit("uplodeChange", {
        [this.keyName]: value,
      });
    },
  },
  mounted() {},
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
.up_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tip {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  max-width: 322px;
  line-height: 20px;
  letter-spacing: 0.69px;
}

.flex_box {
  display: flex;
}
.bottom_tip_box {
  line-height: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 6px;
}
.wap {
  .tip {
    padding-left: 0px;
  }
}

::v-deep .uplode {
  width: 135px;
  height: 90px;
  overflow: hidden;
  margin-right: 12px;
  min-width: 135px;
  .uplode_icon {
    // width: 100%;
    // height: 100%;
    // object-fit: contain;
  }
  .el-upload--picture-card {
    height: 100%;
    width: 100%;
  }
}
.pc_xianzhi {
  // max-width: calc(100vw * 90 / 192);
  // max-height: calc(100vw * 90 / 192);
}
.uplode_icon {
  width: 135px;
  height: 90px;
  display: flex;
  object-fit: contain;
}
</style>
